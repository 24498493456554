import { useEffect, useState } from 'react';
import { postInvite } from '../../core/api';
import { useToast } from '../../contexts/useToast';
import { Spinner } from '../Spinner';
import { Button } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useSteps } from '../../contexts/useSteps';

const FileInvite = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();
  const { setFileInviteLink, fileInviteLink } = useSteps();

  useEffect(() => {
    setIsLoading(true);
    postInvite()
      .then((res) => {
        setFileInviteLink(res.url);
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        Sentry.captureException(err);
        toast(err.response?.data?.message || 'Something went wrong', 'error');
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading && (
        <Spinner text='Please wait while your link is being generated' />
      )}
      {fileInviteLink && (
        <div className='space-y-4 text-center'>
          <Button
            href={fileInviteLink}
            target='_blank'
            rel='noopener noreferrer'
            variant='contained'
          >
            Open file invite link in the new tab
          </Button>
        </div>
      )}
    </>
  );
};

export default FileInvite;
