import { ChangeEvent, ChangeEventHandler, useMemo, useState } from 'react';
import { InputMask } from '../InputMask';
import LoadingButton from '@mui/lab/LoadingButton';
import { postSurvey } from '../../core/api';
import { useAuth0 } from '@auth0/auth0-react';
import { FormikErrors, useFormik } from 'formik';
import { PostSurveyInput, SurveyFormValues } from '../../types';
import CheckIcon from '@mui/icons-material/Check';
import { useSteps } from '../../contexts/useSteps';
import { useToast } from '../../contexts/useToast';
import { AddressInput } from '../AddressInput';
import { InputWithAutocomplete } from '../InputWithAutocomplete';
import {
  getMonth,
  isEmail,
  isFEINValid,
  legalTitleOptions,
  STATES_OPTIONS,
  taxFormOptions,
  months,
  isPhoneValid,
} from './Survey.helpers';
import { sleep } from '../../core/helpers';
import * as Sentry from '@sentry/react';
import { RadioBtn } from '../RadioBtn';
import { Checkbox } from '../Checkbox';
import { Input } from '../Input';
import { Select } from '../Select';

const normalizeSurveyFormData = (
  formValues: SurveyFormValues,
  userEmail: string,
  contactPhone: string
): PostSurveyInput => {
  return {
    ...formValues,
    docusign__signer__first_name: formValues.firstname_dsh,
    docusign__signer__last_name: formValues.lastname_dsh,
    docusign__signer__full_name: `${formValues.firstname_dsh} ${formValues.lastname_dsh}`,
    docusign__signer__phone_number: formValues.company_phone_dsh,
    docusign__signer__title: formValues.title_dsh,
    docusign_ein_tin: formValues.fein_dsh,
    docusign_legal_company_name: formValues.legal_company_name_dsh,
    docusign_street_address: formValues.streetaddress_dsh,
    docusign_street_address2: formValues.unitnumber_dsh,
    docusign_city: formValues.city_dsh,
    docusign_state_region__select_: formValues.state_dsh,
    docusign_postal_code: formValues.zip_dsh,
    docusign__signer__email: formValues.emailforlegalnotices_dsh,
    docusign_point_of_contact_for_legal_notices: formValues.pointofcontact_dsh,
    docusign_email_for_poc_for_legal_notices:
      formValues.emailforlegalnotices_dsh,
    taxstatus_individual_firstname: formValues.firstname_dsh,
    taxstatus_individual_lastname: formValues.lastname_dsh,
    taxstatus_individual_email: userEmail,
    taxstatus_individual_mobile: contactPhone,
    taxstatus_business_fein: formValues.fein_dsh,
    taxstatus_business_phone: contactPhone,
    taxstatus_business_businessname: formValues.legal_company_name_dsh,
    taxstatus_business_title: formValues.legaltitle_dsh,
    taxstatus_business_fiscal: String(getMonth(formValues.fiscalyearend_dsh)),
    taxstatus_business_street:
      formValues.businessaddress_dsh + ' ' + formValues.business_unitnumber_dsh,
    taxstatus_business_city: formValues.business_city_dsh,
    taxstatus_business_state: formValues.business_state_dsh,
    taxstatus_business_zip: formValues.business_zip_dsh,
    dashboard_current_step: '1',
  };
};

const Survey = () => {
  const { setCurrentStep } = useSteps();
  const { user } = useAuth0();
  const [isBusinessAddressSame, setIsBusinessAddressSame] = useState(false);
  const { toast } = useToast();

  const years = useMemo(
    () =>
      Array.from({ length: new Date().getFullYear() - 1950 + 1 }, (_, index) =>
        String(1950 + index)
      ).reverse(),
    []
  );

  const formik = useFormik<SurveyFormValues>({
    enableReinitialize: true,
    initialValues: {
      firstname_dsh: '',
      lastname_dsh: '',
      company_phone_dsh: '',
      title_dsh: '',
      legaltitle_dsh: '',
      legal_company_name_dsh: '',
      fein_dsh: '',
      pointofcontact_dsh: '',
      streetaddress_dsh: '',
      unitnumber_dsh: '',
      city_dsh: '',
      state_dsh: '',
      zip_dsh: '',
      business_unitnumber_dsh: '',
      businessaddress_dsh: '',
      business_city_dsh: '',
      business_state_dsh: '',
      business_zip_dsh: '',
      emailforlegalnotices_dsh: '',
      isowner_dsh: '',
      filedbefore_dsh: '',
      filed2020q2_dsh: 'No',
      filed2020q3_dsh: 'No',
      filed2020q4_dsh: 'No',
      filed2021q1_dsh: 'No',
      filed2021q2_dsh: 'No',
      filed2021q3_dsh: 'No',
      filed2021q4_dsh: 'No',
      numemployees2019_dsh: '',
      numemployees2020_dsh: '',
      numemployees2021_dsh: '',
      taxform_dsh: '',
      fiscalyearend_dsh: '',
      firstyeartaxreturn_dsh: '',
    },
    validate: (values) => {
      const errors: FormikErrors<SurveyFormValues> = {};

      if (!values.firstname_dsh) {
        errors.firstname_dsh = 'First name of contract signatory is required';
      }
      if (!values.lastname_dsh) {
        errors.lastname_dsh = 'Last name of contract signatory is required';
      }
      if (!values.company_phone_dsh) {
        errors.company_phone_dsh = 'Required';
      }
      if (values.company_phone_dsh && !isPhoneValid(values.company_phone_dsh)) {
        errors.company_phone_dsh = 'Phone is invalid';
      }
      if (!values.title_dsh) {
        errors.title_dsh = 'Position/Title of contract signatory is required';
      }
      if (!values.legaltitle_dsh) {
        errors.legaltitle_dsh = 'Legal title of contract signatory is required';
      }
      if (!values.legal_company_name_dsh) {
        errors.legal_company_name_dsh = 'Required';
      }
      if (!values.fein_dsh) {
        errors.fein_dsh =
          'Federal Employer Identification Number (FEIN) is required';
      }
      if (!values.pointofcontact_dsh) {
        errors.pointofcontact_dsh =
          'Point of contact for cegal cotices is required';
      }
      if (!values.streetaddress_dsh) {
        errors.streetaddress_dsh =
          'Street address for (legal) notices is required';
      }
      // if (!values.unitnumber_dsh) {
      //   errors.unitnumber_dsh = 'Unit number for notices address is required';
      // }
      if (!values.city_dsh) {
        errors.city_dsh = 'Required';
      }
      if (!values.state_dsh) {
        errors.state_dsh = 'Required';
      }
      if (!values.zip_dsh) {
        errors.zip_dsh = 'Required';
      }
      if (!values.businessaddress_dsh) {
        errors.businessaddress_dsh = 'Required';
      }
      // if (!values.business_unitnumber_dsh) {
      //   errors.business_unitnumber_dsh = 'Required';
      // }
      if (!values.business_city_dsh) {
        errors.business_city_dsh = 'Required';
      }
      if (!values.business_state_dsh) {
        errors.business_state_dsh = 'Required';
      }
      if (!values.business_zip_dsh) {
        errors.business_zip_dsh = 'Required';
      }
      if (!values.emailforlegalnotices_dsh) {
        errors.emailforlegalnotices_dsh = 'Email for legal notices is required';
      }
      if (!values.isowner_dsh) {
        errors.isowner_dsh = 'Required';
      }
      if (!values.filedbefore_dsh) {
        errors.filedbefore_dsh = 'Required';
      }
      // if (!values.filed2020q2_dsh) {
      //   errors.filed2020q2_dsh = 'Required';
      // }
      // if (!values.filed2020q3_dsh) {
      //   errors.filed2020q3_dsh = 'Required';
      // }
      // if (!values.filed2020q4_dsh) {
      //   errors.filed2020q4_dsh = 'Required';
      // }
      // if (!values.filed2021q1_dsh) {
      //   errors.filed2021q1_dsh = 'Required';
      // }
      // if (!values.filed2021q2_dsh) {
      //   errors.filed2021q2_dsh = 'Required';
      // }
      // if (!values.filed2021q3_dsh) {
      //   errors.filed2021q3_dsh = 'Required';
      // }
      // if (!values.filed2021q4_dsh) {
      //   errors.filed2021q4_dsh = 'Required';
      // }
      if (!values.numemployees2019_dsh) {
        errors.numemployees2019_dsh =
          'Please type the number of employees you had in 2019?';
      }
      if (!values.numemployees2020_dsh) {
        errors.numemployees2020_dsh =
          'Please type the number of employees you had in 2020?';
      }
      if (!values.numemployees2021_dsh) {
        errors.numemployees2021_dsh =
          'Please type the number of employees you had in 2021?';
      }
      if (!values.taxform_dsh) {
        errors.taxform_dsh = 'Please select which tax form do you file?';
      }
      if (!values.fiscalyearend_dsh) {
        errors.fiscalyearend_dsh =
          'Please select what month is your fiscal year end?';
      }
      if (!values.firstyeartaxreturn_dsh) {
        errors.firstyeartaxreturn_dsh =
          'Please what is the first year in which you filed a tax return?';
      }

      const curYear = new Date().getFullYear();
      if (
        values.firstyeartaxreturn_dsh &&
        (+values.firstyeartaxreturn_dsh < 1950 ||
          +values.firstyeartaxreturn_dsh > curYear)
      ) {
        errors.firstyeartaxreturn_dsh = `Year should be between 1950 and ${curYear}`;
      }

      if (
        values.emailforlegalnotices_dsh &&
        !isEmail(values.emailforlegalnotices_dsh)
      ) {
        errors.emailforlegalnotices_dsh = 'Invalid email address';
      }

      if (values.fein_dsh && !isFEINValid(values.fein_dsh)) {
        errors.fein_dsh = 'Invalid FEIN';
      }

      return errors;
    },
    onSubmit: (values) => {
      if (!user) return;

      postSurvey(
        normalizeSurveyFormData(
          values,
          user?.email!,
          values.company_phone_dsh.replace(/-/g, '')
        )
      )
        .then(() => {
          return sleep(1000);
        })
        .then(() => {
          setCurrentStep((prev) => prev + 1);
          toast('Form submitted successfully!', 'success');
        })
        .catch((err) => {
          Sentry.captureException(err);
          toast(err.response?.data?.message || 'Something went wrong', 'error');
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const onChangeNumberField = (value: string, name: string) => {
    if (isNaN(+value)) {
      return;
    }

    formik.setFieldTouched(name, true, true);
    formik.setFieldValue(name, value.split('.')[0], true);
  };

  const onChangeNumberInput: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    onChangeNumberField(e.target.value, e.target.name);
  };

  const onCheckboxChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, checked } = e.target;
    formik.setFieldValue(name, checked ? 'Yes' : 'No', true);
    formik.setFieldTouched(name, true, true);
  };

  const isChecked = (value: string) => {
    return value === 'Yes';
  };

  return (
    <div className='rounded-md bg-white px-8 py-6'>
      <form onSubmit={formik.handleSubmit} noValidate className=''>
        <div className='grid-cols-2 gap-x-6 space-y-8 lg:grid lg:space-y-0 xl:gap-x-8'>
          <div className='space-y-6'>
            <div className='text-semibold font-poppins text-xl text-secondary'>
              Personal Information
            </div>
            <Input
              label='First name of contract signatory'
              name='firstname_dsh'
              value={formik.values.firstname_dsh}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.errors.firstname_dsh && formik.touched.firstname_dsh
                  ? formik.errors.firstname_dsh
                  : ''
              }
            />
            <Input
              label='Last name of contract signatory'
              name='lastname_dsh'
              value={formik.values.lastname_dsh}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.errors.lastname_dsh && formik.touched.lastname_dsh
                  ? formik.errors.lastname_dsh
                  : ''
              }
            />
            <Input
              label='Position/Title of contract signatory'
              name='title_dsh'
              value={formik.values.title_dsh}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.errors.title_dsh && formik.touched.title_dsh
                  ? formik.errors.title_dsh
                  : ''
              }
            />
            <Select
              label='Legal title of contract signatory'
              value={formik.values.legaltitle_dsh}
              onChange={(v) => {
                formik.setFieldValue('legaltitle_dsh', v, true);
              }}
              onClose={() => {
                formik.setFieldTouched('legaltitle_dsh', true, true);
              }}
              options={legalTitleOptions.map((v) => ({
                value: v.split('-').slice(-1)[0].trim(),
                label: v,
              }))}
              error={
                (formik.touched.legaltitle_dsh &&
                  formik.errors.legaltitle_dsh) ||
                ''
              }
            />
            <Input
              label='Legal company name'
              name='legal_company_name_dsh'
              value={formik.values.legal_company_name_dsh}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.errors.legal_company_name_dsh &&
                formik.touched.legal_company_name_dsh
                  ? formik.errors.legal_company_name_dsh
                  : ''
              }
            />
            <InputMask
              name='company_phone_dsh'
              value={formik.values.company_phone_dsh}
              mask='000-000-0000'
              onChange={(v) => {
                formik.setFieldValue('company_phone_dsh', v, true);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.errors.company_phone_dsh &&
                formik.touched.company_phone_dsh
                  ? formik.errors.company_phone_dsh
                  : ''
              }
              label='Company phone'
            />
            <InputMask
              name='fein_dsh'
              mask='00-0000000'
              value={formik.values.fein_dsh}
              onChange={(v) => {
                formik.setFieldValue('fein_dsh', v, true);
              }}
              onBlur={formik.handleBlur}
              label='Federal employer identification number (FEIN)'
              error={
                formik.errors.fein_dsh && formik.touched.fein_dsh
                  ? formik.errors.fein_dsh
                  : ''
              }
            />
            <AddressInput
              label='Street address for legal notices'
              name='streetaddress_dsh'
              error={
                formik.errors.streetaddress_dsh &&
                formik.touched.streetaddress_dsh
                  ? formik.errors.streetaddress_dsh
                  : ''
              }
              onBlur={formik.handleBlur}
              value={formik.values.streetaddress_dsh}
              onChange={(v) => {
                formik.setFieldValue('streetaddress_dsh', v, true);
                if (isBusinessAddressSame) {
                  formik.setFieldValue('businessaddress_dsh', v, true);
                  formik.setFieldTouched('businessaddress_dsh', true, true);
                }
              }}
            />

            <Input
              label='Unit number for legal notices'
              name='unitnumber_dsh'
              value={formik.values.unitnumber_dsh}
              onChange={
                isBusinessAddressSame
                  ? (e) => {
                      formik.setFieldValue(e.target.name, e.target.value, true);
                      formik.setFieldTouched(e.target.name, true, true);
                      formik.setFieldValue(
                        'business_unitnumber_dsh',
                        e.target.value,
                        true
                      );
                      formik.setFieldTouched(
                        'business_unitnumber_dsh',
                        true,
                        true
                      );
                    }
                  : formik.handleChange
              }
              onBlur={formik.handleBlur}
              error={
                formik.errors.unitnumber_dsh && formik.touched.unitnumber_dsh
                  ? formik.errors.unitnumber_dsh
                  : ''
              }
            />
            <AddressInput
              label='City for legal notices'
              name='city_dsh'
              error={
                formik.errors.city_dsh && formik.touched.city_dsh
                  ? formik.errors.city_dsh
                  : ''
              }
              onBlur={formik.handleBlur}
              value={formik.values.city_dsh}
              onChange={(v) => {
                formik.setFieldValue('city_dsh', v, true);
                if (isBusinessAddressSame) {
                  formik.setFieldValue('business_city_dsh', v, true);
                  formik.setFieldTouched('business_city_dsh', true, true);
                }
              }}
            />
            <Select
              label='State for legal notices'
              value={formik.values.state_dsh}
              onChange={
                isBusinessAddressSame
                  ? (v) => {
                      formik.setFieldValue('state_dsh', v, true);
                      formik.setFieldValue('business_state_dsh', v, true);
                    }
                  : (v) => formik.setFieldValue('state_dsh', v, true)
              }
              onClose={() => {
                formik.setFieldTouched('state_dsh', true, true);
              }}
              error={
                (formik.touched.state_dsh && formik.errors.state_dsh) || ''
              }
              options={STATES_OPTIONS.map((v) => ({
                value: v.value,
                label: v.name,
              }))}
            />
            <Input
              label='Zip for legal notices'
              name='zip_dsh'
              value={formik.values.zip_dsh}
              onChange={
                isBusinessAddressSame
                  ? (e: ChangeEvent<HTMLInputElement>) => {
                      let { value } = e.target;
                      value = value.slice(0, 5);
                      if (isNaN(+value)) {
                        return;
                      }

                      onChangeNumberField(value, e.target.name);
                      formik.setFieldValue('business_zip_dsh', value, true);
                      formik.setFieldTouched('business_zip_dsh', true, true);
                    }
                  : (e) =>
                      onChangeNumberField(
                        e.target.value.slice(0, 5),
                        e.target.name
                      )
              }
              onBlur={formik.handleBlur}
              error={
                formik.errors.zip_dsh && formik.touched.zip_dsh
                  ? formik.errors.zip_dsh
                  : ''
              }
            />
            <Input
              label='Point of contact for legal notices'
              name='pointofcontact_dsh'
              value={formik.values.pointofcontact_dsh}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.errors.pointofcontact_dsh &&
                formik.touched.pointofcontact_dsh
                  ? formik.errors.pointofcontact_dsh
                  : ''
              }
            />
            <div>
              <Input
                label='Email for legal notices'
                type='email'
                name='emailforlegalnotices_dsh'
                value={formik.values.emailforlegalnotices_dsh}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.emailforlegalnotices_dsh &&
                  formik.touched.emailforlegalnotices_dsh
                    ? formik.errors.emailforlegalnotices_dsh
                    : ''
                }
              />
              <Checkbox
                wrapperClassName='mt-3'
                label='Is the address you used on your last tax return the same
                    address listed above?'
                checked={isBusinessAddressSame}
                onChange={(e) => {
                  if (e.target.checked) {
                    formik.setFieldValue(
                      'business_city_dsh',
                      formik.values.city_dsh,
                      true
                    );
                    formik.setFieldValue(
                      'business_state_dsh',
                      formik.values.state_dsh,
                      true
                    );
                    formik.setFieldValue(
                      'business_zip_dsh',
                      formik.values.zip_dsh,
                      true
                    );
                    formik.setFieldValue(
                      'business_unitnumber_dsh',
                      formik.values.unitnumber_dsh,
                      true
                    );
                    formik.setFieldValue(
                      'businessaddress_dsh',
                      formik.values.streetaddress_dsh,
                      true
                    );
                  }
                  setIsBusinessAddressSame(e.target.checked);
                }}
              />
            </div>
            <AddressInput
              label='Street of business address was used on your last tax return'
              name='businessaddress_dsh'
              error={
                formik.errors.businessaddress_dsh &&
                formik.touched.businessaddress_dsh
                  ? formik.errors.businessaddress_dsh
                  : ''
              }
              onBlur={formik.handleBlur}
              value={formik.values.businessaddress_dsh}
              onChange={(v) => {
                formik.setFieldValue('businessaddress_dsh', v, true);
                formik.setFieldTouched('businessaddress_dsh', true, true);
              }}
              disabled={isBusinessAddressSame}
            />
            <Input
              label='Unit number of business address was used on your last tax
                return'
              name='business_unitnumber_dsh'
              value={formik.values.business_unitnumber_dsh}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isBusinessAddressSame}
              error={
                formik.errors.business_unitnumber_dsh &&
                formik.touched.business_unitnumber_dsh
                  ? formik.errors.business_unitnumber_dsh
                  : ''
              }
            />
            <AddressInput
              label='City of business address was used on your last tax return'
              name='business_city_dsh'
              error={
                formik.errors.business_city_dsh &&
                formik.touched.business_city_dsh
                  ? formik.errors.business_city_dsh
                  : ''
              }
              onBlur={formik.handleBlur}
              value={formik.values.business_city_dsh}
              onChange={(v) => {
                formik.setFieldValue('business_city_dsh', v, true);
                formik.setFieldTouched('business_city_dsh', true, true);
              }}
              disabled={isBusinessAddressSame}
            />
            <Select
              label='State of business address was used on your last tax return'
              value={formik.values.business_state_dsh}
              onChange={(v) =>
                formik.setFieldValue('business_state_dsh', v, true)
              }
              onClose={() => {
                formik.setFieldTouched('business_state_dsh', true, true);
              }}
              error={
                (formik.touched.business_state_dsh &&
                  formik.errors.business_state_dsh) ||
                ''
              }
              options={STATES_OPTIONS.map((v) => ({
                value: v.value,
                label: v.name,
              }))}
              disabled={isBusinessAddressSame}
            />
            <Input
              label='Zip of business address was used on your last tax return'
              name='business_zip_dsh'
              value={formik.values.business_zip_dsh}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChangeNumberField(e.target.value.slice(0, 5), e.target.name);
              }}
              onBlur={formik.handleBlur}
              disabled={isBusinessAddressSame}
              error={
                formik.errors.business_zip_dsh &&
                formik.touched.business_zip_dsh
                  ? formik.errors.business_zip_dsh
                  : ''
              }
            />
          </div>
          <div className='space-y-6'>
            <div className='text-semibold font-poppins text-xl text-secondary'>
              ERC Estimate
            </div>
            <div>
              <div className='mb-1 text-sm text-[#5A607F]'>
                Are you the Owner or acting on behalf of the owner?
              </div>
              <div className='mt-1 flex min-h-[42px] items-center gap-x-6'>
                <RadioBtn
                  checked={formik.values.isowner_dsh === 'Yes'}
                  onChange={() => {
                    formik.setFieldValue('isowner_dsh', 'Yes');
                  }}
                  label='Yes'
                  error={
                    formik.touched.isowner_dsh && !!formik.errors.isowner_dsh
                  }
                />
                <RadioBtn
                  checked={formik.values.isowner_dsh === 'No'}
                  onChange={() => {
                    formik.setFieldValue('isowner_dsh', 'No');
                  }}
                  label='No'
                  error={
                    formik.touched.isowner_dsh && !!formik.errors.isowner_dsh
                  }
                />
              </div>
              {formik.touched.isowner_dsh && formik.errors.isowner_dsh && (
                <div className='text-xs text-[#F32227]'>
                  Please select whether you are the owner or acting on behalf of
                  the owner.
                </div>
              )}
            </div>

            <div>
              <div className='mb-1 text-sm text-[#5A607F]'>
                Have you previously filed for ERC?
              </div>
              <div className='mt-1 flex min-h-[42px] items-center gap-x-6'>
                <RadioBtn
                  checked={formik.values.filedbefore_dsh === 'Yes'}
                  onChange={() => {
                    formik.setFieldValue('filedbefore_dsh', 'Yes');
                  }}
                  label='Yes'
                  error={
                    formik.touched.filedbefore_dsh &&
                    !!formik.errors.filedbefore_dsh
                  }
                />
                <RadioBtn
                  checked={formik.values.filedbefore_dsh === 'No'}
                  onChange={() => {
                    formik.setFieldValue('filedbefore_dsh', 'No');
                  }}
                  label='No'
                  error={
                    formik.touched.filedbefore_dsh &&
                    !!formik.errors.filedbefore_dsh
                  }
                />
              </div>
              {formik.touched.filedbefore_dsh &&
                formik.errors.filedbefore_dsh && (
                  <div className='text-xs text-[#F32227]'>
                    Please select whether you previously filed for ERC.
                  </div>
                )}
            </div>
            <div>
              <div className='mb-1 text-sm text-[#5A607F]'>
                For which Quarters did you file ERC?
              </div>

              <div className='mb-2 flex gap-x-4'>
                <div>2020:</div>
                <div className='flex gap-x-4'>
                  <Checkbox
                    checked={isChecked(formik.values.filed2020q2_dsh)}
                    onChange={onCheckboxChange}
                    onBlur={formik.handleBlur}
                    name='filed2020q2_dsh'
                    label='Q2'
                    error={
                      formik.touched.filed2020q2_dsh &&
                      !!formik.errors.filed2020q2_dsh
                    }
                  />
                  <Checkbox
                    checked={isChecked(formik.values.filed2020q3_dsh)}
                    onChange={onCheckboxChange}
                    onBlur={formik.handleBlur}
                    name='filed2020q3_dsh'
                    label='Q3'
                    error={
                      formik.touched.filed2020q3_dsh &&
                      !!formik.errors.filed2020q3_dsh
                    }
                  />
                  <Checkbox
                    checked={isChecked(formik.values.filed2020q4_dsh)}
                    onChange={onCheckboxChange}
                    onBlur={formik.handleBlur}
                    name='filed2020q4_dsh'
                    label='Q4'
                    error={
                      formik.touched.filed2020q4_dsh &&
                      !!formik.errors.filed2020q4_dsh
                    }
                  />
                </div>
              </div>

              <div className='flex gap-x-4'>
                <div>2021:</div>
                <div className='flex gap-x-4'>
                  <Checkbox
                    checked={isChecked(formik.values.filed2021q1_dsh)}
                    onChange={onCheckboxChange}
                    onBlur={formik.handleBlur}
                    name='filed2021q1_dsh'
                    label='Q1'
                    error={
                      formik.touched.filed2021q1_dsh &&
                      !!formik.errors.filed2021q1_dsh
                    }
                  />
                  <Checkbox
                    checked={isChecked(formik.values.filed2021q2_dsh)}
                    onChange={onCheckboxChange}
                    onBlur={formik.handleBlur}
                    name='filed2021q2_dsh'
                    label='Q2'
                    error={
                      formik.touched.filed2021q2_dsh &&
                      !!formik.errors.filed2021q2_dsh
                    }
                  />
                  <Checkbox
                    checked={isChecked(formik.values.filed2021q3_dsh)}
                    onChange={onCheckboxChange}
                    onBlur={formik.handleBlur}
                    label='Q3'
                    name='filed2021q3_dsh'
                    error={
                      formik.touched.filed2021q3_dsh &&
                      !!formik.errors.filed2021q3_dsh
                    }
                  />
                  <Checkbox
                    checked={isChecked(formik.values.filed2021q4_dsh)}
                    onChange={onCheckboxChange}
                    onBlur={formik.handleBlur}
                    label='Q4'
                    name='filed2021q4_dsh'
                    error={
                      formik.touched.filed2021q4_dsh &&
                      !!formik.errors.filed2021q4_dsh
                    }
                  />
                </div>
              </div>
            </div>
            <div className='!mt-[10px]'>
              <Input
                label='How many employees did you have in 2019?'
                name='numemployees2019_dsh'
                value={formik.values.numemployees2019_dsh}
                onChange={onChangeNumberInput}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.numemployees2019_dsh &&
                  formik.touched.numemployees2019_dsh
                    ? formik.errors.numemployees2019_dsh
                    : ''
                }
              />
            </div>
            <Input
              label='How many employees did you have in 2020?'
              name='numemployees2020_dsh'
              value={formik.values.numemployees2020_dsh}
              onChange={onChangeNumberInput}
              onBlur={formik.handleBlur}
              error={
                formik.errors.numemployees2020_dsh &&
                formik.touched.numemployees2020_dsh
                  ? formik.errors.numemployees2020_dsh
                  : ''
              }
            />
            <Input
              label='How many employees did you have in 2021?'
              name='numemployees2021_dsh'
              value={formik.values.numemployees2021_dsh}
              onChange={onChangeNumberInput}
              onBlur={formik.handleBlur}
              error={
                formik.errors.numemployees2021_dsh &&
                formik.touched.numemployees2021_dsh
                  ? formik.errors.numemployees2021_dsh
                  : ''
              }
            />
            <Select
              label='Which tax form do you file?'
              value={formik.values.taxform_dsh}
              onChange={(v) => {
                formik.setFieldValue('taxform_dsh', v, true);
              }}
              onClose={() => {
                formik.setFieldTouched('taxform_dsh', true, true);
              }}
              options={taxFormOptions.map((v) => ({
                label: v,
                value: v,
              }))}
              error={
                formik.errors.taxform_dsh && formik.touched.taxform_dsh
                  ? formik.errors.taxform_dsh
                  : ''
              }
            />
            <Select
              label='What month is your fiscal year end?'
              value={formik.values.fiscalyearend_dsh}
              onChange={(v) => {
                formik.setFieldValue('fiscalyearend_dsh', v, true);
              }}
              onClose={() => {
                formik.setFieldTouched('fiscalyearend_dsh', true, true);
              }}
              options={months.map((v) => ({ label: v, value: v }))}
              error={
                formik.errors.fiscalyearend_dsh &&
                formik.touched.fiscalyearend_dsh
                  ? formik.errors.fiscalyearend_dsh
                  : ''
              }
            />
            <InputWithAutocomplete
              options={years}
              label='What is the first year in which you filed a tax return?'
              name='firstyeartaxreturn_dsh'
              error={
                formik.errors.firstyeartaxreturn_dsh &&
                formik.touched.firstyeartaxreturn_dsh
                  ? formik.errors.firstyeartaxreturn_dsh
                  : ''
              }
              onBlur={formik.handleBlur}
              value={formik.values.firstyeartaxreturn_dsh.slice(0, 4)}
              onChange={(v) => {
                onChangeNumberField(
                  String(v).slice(0, 4),
                  'firstyeartaxreturn_dsh'
                );
              }}
            />
          </div>
        </div>
        <div className='flex justify-end pt-10'>
          <LoadingButton
            loading={formik.isSubmitting}
            loadingPosition='start'
            startIcon={<CheckIcon />}
            variant='contained'
            type='submit'
            color='secondary'
          >
            Confirm
          </LoadingButton>
        </div>
      </form>
    </div>
  );
};

export default Survey;
