import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    text: {
      primary: '#283445',
      secondary: '#B22335',
    },
    primary: {
      main: '#B22335',
    },
    secondary: {
      main: '#2F3583',
    },
    error: {
      main: '#F32227',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#FDE7EA',
            color: '#D2828D',
          },
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: '#D9E4FF',
            color: '#2E3583',
          },
        },
      },
    },
  },
});

export default theme;
