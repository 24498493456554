import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../components/Spinner';
import { useToast } from '../contexts/useToast';
import * as Sentry from '@sentry/react';

export const Login = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, error, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) {
      loginWithRedirect();
    }

    if (error) {
      console.log('error', error);
      toast('Something went wrong', 'error');
      Sentry.captureException(error);
    }

    if (isAuthenticated) {
      navigate('/');
    }
    //eslint-disable-next-line
  }, [isAuthenticated, isLoading, navigate, error]);

  if (isLoading) {
    return <Spinner />;
  }

  return null;
};
