import { useEffect, useState } from 'react';
import { getContact } from '../core/api';
import { Contact } from '../types';

export const useContact = () => {
  const [contact, setContact] = useState<Contact>();
  const [isLoadingContact, setIsLoadingContact] = useState(false);

  useEffect(() => {
    setIsLoadingContact(true);
    getContact()
      .then((res) => {
        setContact(res);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoadingContact(false);
      });
  }, []);

  return {
    contact,
    isLoadingContact,
  };
};
