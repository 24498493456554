import { PropsWithChildren, createContext, useState } from 'react';
import { Toast } from '../components/Toast';
import { AlertColor } from '@mui/material';

interface ToastContextType {
  toast: (msg: string, type: AlertColor) => void;
}

export const ToastContext = createContext<ToastContextType>(
  {} as ToastContextType
);

export const ToastProvider = ({ children }: PropsWithChildren) => {
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState<AlertColor>('success');

  const toast = (msg: string, type: AlertColor) => {
    setToastSeverity(type);
    setToastMessage(msg);
  };

  const onCloseToast = () => {
    setToastMessage('');
  };

  return (
    <ToastContext.Provider value={{ toast }}>
      {children}{' '}
      <Toast
        message={toastMessage}
        isShown={!!toastMessage}
        onClose={onCloseToast}
        severity={toastSeverity}
      />
    </ToastContext.Provider>
  );
};
