import { FocusEventHandler, useEffect, useRef, useState } from 'react';
import { useDebouncedValue } from '../hooks/useDebouncedValue';
import { getAdressPrediction } from '../core/api';
import { InputWithAutocomplete } from './InputWithAutocomplete';

interface Props {
  onChange: (v: string | number) => void;
  value: string;
  onBlur: FocusEventHandler<HTMLInputElement>;
  error: string;
  name: string;
  label: string;
  disabled?: boolean;
}

export const AddressInput = ({
  onChange,
  value,
  onBlur,
  error,
  name,
  label,
  disabled,
}: Props) => {
  const [options, setOptions] = useState<string[]>([]);
  const debauncedValue = useDebouncedValue(value, 250);
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (
      ref.current &&
      document.activeElement === ref.current &&
      debauncedValue
    ) {
      getAdressPrediction(debauncedValue)
        .then((res) => {
          setOptions(
            res.predictions.map((v) => v.structured_formatting.main_text)
          );
        })
        .catch(console.error);
    }
  }, [debauncedValue]);

  return (
    <InputWithAutocomplete
      disabled={disabled}
      ref={ref}
      label={label}
      name={name}
      options={options}
      error={error}
      onBlur={onBlur}
      value={value}
      onChange={(v) => {
        onChange(v);
      }}
    />
  );
};
