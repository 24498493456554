import { useAuth0 } from '@auth0/auth0-react';
import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { Socket, io } from 'socket.io-client';
import { WS_MESSAGE_EVENT_KEY } from '../hooks/useWSMessageHandler';

interface WebsocketContextType {
  sendMessage: (msg: any) => void;
}

export const WebsocketContext = createContext<WebsocketContextType>(
  {} as WebsocketContextType
);

enum SocketEventsEnum {
  CustomEvent = 'custom-event',
}

export const SocketProvider = ({ children }: PropsWithChildren) => {
  const [socket, setSocket] = useState<Socket>();

  const { getAccessTokenSilently } = useAuth0();
  // const socketRef = useRef<Socket | undefined>();

  const emitEvent = async <T,>(event: SocketEventsEnum, data: T) => {
    socket?.emit(event, data);
  };

  const sendMessage = (msg: any) => {
    emitEvent(SocketEventsEnum.CustomEvent, msg);
  };

  const createSocket = async () => {
    const token = await getAccessTokenSilently();

    const newSocket = io(process.env.REACT_APP_SOCKET_URL!, {
      auth: {
        token,
      },
      transports: ['websocket'],
      reconnectionAttempts: 20,
    });
    setSocket(newSocket);

    newSocket.on(SocketEventsEnum.CustomEvent, (msg: any) => {
      document.dispatchEvent(
        new CustomEvent(WS_MESSAGE_EVENT_KEY, {
          detail: msg,
        })
      );
    });

    newSocket.on('disconnect', (reason) => {
      if (reason === 'io server disconnect') {
        newSocket.connect();
      }
    });
  };

  useEffect(() => {
    createSocket();
    // eslint-disable-next-line
  }, []);

  return (
    <WebsocketContext.Provider
      value={{
        sendMessage,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};
