import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Header from '../components/Header';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from '../components/Spinner';
import { Steps } from './Steps';
import { StepsProvider } from '../contexts/steps-context';
import { ToastProvider } from '../contexts/toast-context';
import { TopBar } from '../components/TopBar';
import { SocketProvider } from '../contexts/socket-context';
import { Login } from './Login';
import Hotjar from '@hotjar/browser';

export function AuthedRoutes() {
  const { loginWithRedirect, isAuthenticated, user, isLoading } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }

    if (
      isAuthenticated &&
      process.env.REACT_APP_ENV === 'production' &&
      process.env.NODE_ENV === 'production'
    ) {
      Hotjar.init(4936763, 6);
    }
    //eslint-disable-next-line
  }, [isAuthenticated, isLoading]);

  if (isLoading || !user || !isAuthenticated) {
    return <Spinner />;
  }

  return (
    <SocketProvider>
      <ToastProvider>
        <div className='flex min-h-screen flex-col'>
          <TopBar />
          <Header />
          <div className='flex w-full flex-grow flex-col px-8 pb-6'>
            <div className='m-auto flex w-full max-w-[1250px] flex-grow flex-col'>
              <Routes>
                <Route
                  path='/'
                  element={
                    <StepsProvider>
                      <Steps />
                    </StepsProvider>
                  }
                />
                <Route path='/login' element={<Login />} />
                <Route path='*' element={<Navigate to='/' />} />
              </Routes>
            </div>
          </div>
        </div>
      </ToastProvider>
    </SocketProvider>
  );
}
