import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { initializeInterceptors } from './core/api';
import { AuthedRoutes } from './pages/AuthedRoutes';
import { Spinner } from './components/Spinner';
import { Login } from './pages/Login';
import { Routes, Route, Navigate } from 'react-router-dom';

function App() {
  const [isApiClientReady, setIsApiClientReady] = useState(false);
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout,
    getIdTokenClaims,
  } = useAuth0();

  useEffect(() => {
    if (!isApiClientReady) {
      initializeInterceptors(getAccessTokenSilently, logout, getIdTokenClaims);
      setIsApiClientReady(true);
    }
    // eslint-disable-next-line
  }, []);

  if (isLoading || !isApiClientReady) {
    return <Spinner />;
  }

  if (!isAuthenticated && !isLoading) {
    return (
      <Routes>
        <Route path='/' element={<Navigate to='/login' />} />
        <Route path='/login' element={<Login />} />
      </Routes>
    );
  }

  return <AuthedRoutes />;
}

export default App;
