import { CircularProgress } from '@mui/material';

interface Props {
  text?: string;
}

export const Spinner = ({ text }: Props) => {
  return (
    <div className='fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-[rgba(0,0,0,0.3)]'>
      <CircularProgress color='secondary' />
      {text && (
        <div className='text-center text-2xl mt-4 text-secondary'>{text}</div>
      )}
    </div>
  );
};
