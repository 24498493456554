import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

export const Alert = ({
  className = '',
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={`text-semibold flex flex-row items-start gap-x-2 overflow-hidden rounded-md border bg-primary py-2 pl-4 text-lg text-white shadow ${className}`}
    >
      {children}
    </div>
  );
};
