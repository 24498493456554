import classNames from 'classnames';
import { InputHTMLAttributes } from 'react';

interface Props {
  label: string;
  error?: boolean;
}

export const RadioBtn = ({
  label,
  checked,
  disabled,
  error,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & Props) => {
  return (
    <label className='flex items-center gap-x-4'>
      <span>{label}</span>
      <div
        className={classNames(
          'w-6 h-6 border rounded-full transition-colors relative flex items-center justify-center',
          {
            '!border-[#F32227]': error,
            'hover:border-[#D7DBEC] bg-[#F5F6FA]': disabled && !checked,
            'hover:border-[#D9E1EC] border-[#D7DBEC]': !disabled && !checked,
            'bg-secondary border-secondary': checked,
            'opacity-50': checked && disabled,
            'cursor-not-allowed': disabled,
            'cursor-pointer': !disabled,
          }
        )}
      >
        {checked === true && <div className='w-2 h-2 bg-white rounded-full' />}
      </div>
      <input
        type='radio'
        className='hidden'
        checked={checked || false}
        disabled={disabled}
        {...props}
      />
    </label>
  );
};
