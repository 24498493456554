import { WsEvent } from './hooks/useWSMessageHandler';

export interface SurveyFormValues {
  firstname_dsh: string;
  lastname_dsh: string;
  company_phone_dsh: string;
  title_dsh: string;
  legaltitle_dsh: string;
  legal_company_name_dsh: string;
  fein_dsh: string;
  pointofcontact_dsh: string;
  streetaddress_dsh: string;
  unitnumber_dsh: string;
  city_dsh: string;
  state_dsh: string;
  zip_dsh: string;
  business_unitnumber_dsh: string;
  businessaddress_dsh: string;
  business_city_dsh: string;
  business_state_dsh: string;
  business_zip_dsh: string;
  emailforlegalnotices_dsh: string;
  isowner_dsh: string;
  filedbefore_dsh: string;
  filed2020q2_dsh: string;
  filed2020q3_dsh: string;
  filed2020q4_dsh: string;
  filed2021q1_dsh: string;
  filed2021q2_dsh: string;
  filed2021q3_dsh: string;
  filed2021q4_dsh: string;
  numemployees2019_dsh: string;
  numemployees2020_dsh: string;
  numemployees2021_dsh: string;
  taxform_dsh: string;
  fiscalyearend_dsh: string;
  firstyeartaxreturn_dsh: string;
}

export interface PostSurveyInput extends SurveyFormValues {
  docusign__signer__email: string;
  docusign__signer__first_name: string;
  docusign__signer__full_name: string;
  docusign__signer__last_name: string;
  docusign__signer__phone_number: string;
  docusign__signer__title: string;
  docusign_city: string;
  docusign_ein_tin: string;
  docusign_legal_company_name: string;
  docusign_postal_code: string;
  docusign_state_region__select_: string;
  docusign_street_address: string;
  docusign_street_address2: string;
  docusign_point_of_contact_for_legal_notices: string;
  docusign_email_for_poc_for_legal_notices: string;
  taxstatus_individual_firstname: string;
  taxstatus_individual_lastname: string;
  taxstatus_individual_email: string;
  taxstatus_individual_mobile: string;
  taxstatus_business_fein: string;
  taxstatus_business_phone: string;
  taxstatus_business_businessname: string;
  taxstatus_business_title: string;
  taxstatus_business_fiscal: string;
  taxstatus_business_street: string;
  taxstatus_business_city: string;
  taxstatus_business_state: string;
  taxstatus_business_zip: string;
  dashboard_current_step: string;
  docusign_envelop_id?: string;
  taxstatus_consent_link_dsh?: string;
  taxstatus_acceptance_status?: string;
}

interface Consent {
  Last4: string;
  FirstName: string;
  LastName: string;
  ConsentLink: string;
}

export interface TaxpayersResponse {
  CaseLink: string;
  ConsentLinks: Consent[];
}

export interface Contact extends Partial<PostSurveyInput> {
  firstname?: string;
  lastname?: string;
  are_you_a_company_owner?: string;
  number_of_w_2_employees?: string;
  have_you_already_submitted_your_erc?: string;
  erc_quarters_filed?: string;
  preferred_communication_language?: string;
}

export interface Step {
  step: string;
  image: string;
  component: JSX.Element | null;
  imageClassName?: string;
}

export interface HookEnvelopeEvent {
  event: WsEvent.ENVELOPE_COMPLETED;
  apiVersion: string;
  uri: string;
  retryCount: number;
  configurationId: number;
  generatedDateTime: string;
  data: {
    accountId: string;
    userId: string;
    envelopeId: string;
  };
}

export interface InviteStatusChangedEvent {
  event: WsEvent.INVITE_STATUS_CHANGED;
  id: string;
  reference_id: string;
  status: InviteStatusEnum;
  contact_id: string;
  time: string;
  sub: string;
  email: string;
}

export interface UserEnvelopeEvent extends HookEnvelopeEvent {
  sub: string;
  email: string;
}
export interface TaxstatusEvent extends HookEnvelopeEvent {
  sub: string;
  email: string;
  status: string;
}

export enum InviteStatusEnum {
  sent = 'sent',
  viewed = 'viewed',
  progressing = 'progressing',
  returned = 'returned',
  closed = 'closed',
}

export interface PostInviteResponse {
  url: string;
  status?: InviteStatusEnum;
}
interface Prediction {
  description: string;
  matched_substrings: {
    length: number;
    offset: number;
  }[];
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    main_text_matched_substrings: {
      length: number;
      offset: number;
    }[];
    secondary_text: string;
  };
  terms: {
    offset: number;
    value: string;
  }[];
  types: string[];
}

export interface PredictionsResponse {
  predictions: Prediction[];
  status: string;
}

export interface PostConnectInput {
  code: string;
}
export interface SelectOption {
  label: string;
  value: string;
}

export interface DirectoryResponse {
  paging: Paging;
  individuals: Individual[];
}

interface Paging {
  count: number;
  offset: number;
}

export interface Individual {
  id: string;
  first_name: string;
  last_name: string;
  middle_name: string | null;
  department: Department | null;
  manager: any;
  is_active: boolean;
}

interface Department {
  id: string;
  name: string;
}
