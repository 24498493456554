import { Box } from '@mui/material';

const Header = () => {
  return (
    <Box bgcolor={'white'} paddingX={4}>
      <Box maxWidth={1250} margin={'0 auto'}>
        <Box
          width={{
            xs: 114,
            md: 184,
          }}
        >
          <img src='/images/logo.png' alt='logo' />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
