import { PropsWithChildren, createContext, useState } from 'react';

interface StepsContextType {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setPayrollLink: React.Dispatch<React.SetStateAction<string>>;
  payrollLink: string;
  setFileInviteLink: React.Dispatch<React.SetStateAction<string>>;
  fileInviteLink: string;
}

export const StepsContext = createContext<StepsContextType>(
  {} as StepsContextType
);

export const StepsProvider = ({ children }: PropsWithChildren) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [payrollLink, setPayrollLink] = useState('');
  const [fileInviteLink, setFileInviteLink] = useState('');

  return (
    <StepsContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        payrollLink,
        setPayrollLink,
        fileInviteLink,
        setFileInviteLink,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
};
