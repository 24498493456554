import { Snackbar } from '@mui/material';
import Alert, { AlertColor } from '@mui/material/Alert';
import { useDebouncedValue } from '../hooks/useDebouncedValue';
interface ToastProps {
  isShown: boolean;
  onClose?: () => void;
  message: string;
  severity?: AlertColor;
}

export const Toast = ({ isShown, onClose, message, severity }: ToastProps) => {
  const deboucedMessage = useDebouncedValue(message, 300);

  return (
    <Snackbar open={isShown} autoHideDuration={3500} onClose={onClose}>
      <Alert
        onClose={onClose}
        severity={severity || 'success'}
        variant='filled'
        sx={{ width: '100%' }}
      >
        {message || deboucedMessage}
      </Alert>
    </Snackbar>
  );
};
