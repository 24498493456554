import classNames from 'classnames';
import { InputHTMLAttributes } from 'react';
import { ReactComponent as CheckboxIcon } from '../icons/checkbox.svg';

interface Props {
  label?: string;
  error?: boolean;
  wrapperClassName?: string;
}

export const Checkbox = ({
  label,
  checked,
  disabled,
  error,
  wrapperClassName = '',
  ...props
}: InputHTMLAttributes<HTMLInputElement> & Props) => {
  return (
    <label className={`flex items-start gap-x-2 ${wrapperClassName}`}>
      <div
        className={classNames(
          'w-5 h-5 border rounded transition-colors relative flex items-center justify-center shrink-0',
          {
            '!border-[#F32227]': error,
            'hover:border-[#D7DBEC] bg-[#F5F6FA]': disabled && !checked,
            'hover:border-[#D9E1EC] border-[#D7DBEC]': !disabled && !checked,
            'bg-secondary border-secondary': checked,
            'opacity-50': checked && disabled,
            'mt-px': label,
            'cursor-not-allowed': disabled,
            'cursor-pointer': !disabled,
          }
        )}
      >
        {checked === true && <CheckboxIcon />}
      </div>
      {label && <span>{label}</span>}
      <input
        type='checkbox'
        className='hidden'
        checked={checked || false}
        disabled={disabled}
        {...props}
      />
    </label>
  );
};
