import classNames from 'classnames';
import { InputHTMLAttributes, forwardRef } from 'react';

interface Props {
  label?: string;
  wrapperClassName?: string;
  className?: string;
  error?: string;
  icon?: JSX.Element;
}

export const Input = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & Props
>(
  (
    { label, wrapperClassName = '', className = '', error, icon, ...props },
    ref
  ) => {
    return (
      <div className={wrapperClassName}>
        {label && <div className='mb-1 text-sm text-[#5A607F]'>{label}</div>}
        <div className='relative'>
          <input
            {...props}
            ref={ref}
            className={classNames(
              'border rounded py-2 px-4 text-[#5A607F]  placeholder:text-[#D9E1EC] w-full disabled:bg-gray-300 disabled:cursor-not-allowed',
              className,
              {
                'border-[#D9E1EC] focus:outline-secondary': !error,
                'border-[#F32227] focus:outline-[#F32227]': error,
                '!pr-10': icon,
              }
            )}
          />
          {icon && (
            <div className='absolute right-2 top-1/2 transform -translate-y-1/2'>
              {icon}
            </div>
          )}
        </div>
        {error && <div className='text-[#F32227] text-xs'>{error}</div>}
      </div>
    );
  }
);
