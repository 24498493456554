import { postEnvelope } from '../../core/api';
import { useCallback, useEffect, useState } from 'react';
import { Spinner } from '../Spinner';
import { sleep } from '../../core/helpers';
import * as Sentry from '@sentry/react';
import { useToast } from '../../contexts/useToast';

const DocuSign = () => {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [link, setLink] = useState('');

  const refetchPostEnvelope = useCallback(async (count = 1) => {
    try {
      const res = await postEnvelope();
      setLink(res.url);
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    } catch (err: any) {
      if (err.response?.data.code === 'ContactNotFound' && count < 3) {
        await sleep(1000);
        await refetchPostEnvelope(count + 1);
      } else {
        Sentry.captureException(err);
        toast(err.response?.data?.message || 'Something went wrong', 'error');
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refetchPostEnvelope();
  }, [refetchPostEnvelope]);

  return (
    <div>
      {isLoading && (
        <Spinner text='Please wait while your link is being generated' />
      )}
      {link && (
        <iframe
          className='border-none rounded-md w-full min-h-screen'
          title='DocuSign'
          src={link || 'https://www.docusign.com'}
          onLoad={() => {
            setTimeout(() => {
              setIsLoading(false);
            }, 3000);
          }}
        />
      )}
    </div>
  );
};

export default DocuSign;
