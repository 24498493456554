import { DependencyList, useEffect } from 'react';
import {
  InviteStatusChangedEvent,
  TaxstatusEvent,
  UserEnvelopeEvent,
} from '../types';

export const WS_MESSAGE_EVENT_KEY = 'ws-message';

export type WsMessage = {
  type: string;
  detail: any;
};

export enum WsEvent {
  ENVELOPE_COMPLETED = 'envelope-completed',
  TAXSTATUS_EVENT = 'taxstatus_event',
  //
  INVITE_STATUS_CHANGED = 'invite_status_changed',
  FINCH_CONNECTED = 'finch_connected',
}

export const isEnvelopeEvent = (v: any): v is UserEnvelopeEvent => {
  return v.event === WsEvent.ENVELOPE_COMPLETED;
};

export const isTaxstatusEvent = (v: any): v is TaxstatusEvent => {
  return v.event === WsEvent.TAXSTATUS_EVENT;
};

export const isFileinviteEvent = (v: any): v is InviteStatusChangedEvent => {
  return v.event === WsEvent.INVITE_STATUS_CHANGED;
};

export const useWSMessageHandler = (
  handler: (msg: WsMessage) => void,
  deps: DependencyList
) => {
  useEffect(() => {
    const listener = (e: CustomEvent) => {
      handler(e.detail);
    };
    document.addEventListener(WS_MESSAGE_EVENT_KEY as any, listener);
    return () => {
      document.removeEventListener(WS_MESSAGE_EVENT_KEY as any, listener);
    };
    // eslint-disable-next-line
  }, deps);
};
