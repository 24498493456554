import { useIMask } from 'react-imask';
import { Input } from './Input';
import { InputHTMLAttributes } from 'react';

interface Props {
  label?: string;
  wrapperClassName?: string;
  className?: string;
  error?: string;
  icon?: JSX.Element;
  onChange: (v: string) => void;
  mask: any;
}

export function InputMask({
  label,
  wrapperClassName,
  className,
  error,
  icon,
  onChange,
  mask = Number,
  onBlur,
  name,
}: InputHTMLAttributes<HTMLInputElement> & Props) {
  const { ref } = useIMask(
    {
      mask,
      lazy: true,
    },
    {
      onAccept: (v) => {
        onChange(v);
      },
    }
  );

  return (
    <Input
      ref={ref as any}
      placeholder=''
      label={label}
      wrapperClassName={wrapperClassName}
      className={className}
      error={error}
      icon={icon}
      onBlur={onBlur}
      name={name}
    />
  );
}
