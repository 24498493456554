import {
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useClickAway } from 'react-use';
import { SelectOption } from '../types';
import classNames from 'classnames';
import { ChevronRight } from '@mui/icons-material';

interface Props {
  onChange: (v: string | number) => void;
  value: string;
  error?: string;
  options: SelectOption[];
  label?: string;
  onClose?: () => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}

export const Select = ({
  onChange,
  value,
  error,
  options,
  label,
  onClose,
  className = '',
  placeholder = '',
  disabled,
}: Props) => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [isShown, setIsShown] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const close = () => {
    setIsShown(false);
    if (onClose && isShown) {
      setTimeout(() => {
        onClose();
      }, 0);
    }
  };
  useClickAway(ref, close);

  const optionsLabels = useMemo(
    () =>
      options.reduce((acc, v) => {
        acc[v.value] = v.label;
        return acc;
      }, {} as Record<string, string>),
    [options]
  );

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (disabled || !isShown || (isShown && !options.length)) return;

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex((prev) =>
        prev === undefined || prev === options.length - 1 ? 0 : prev + 1
      );
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex((prev) =>
        prev === undefined || prev === 0 ? options.length - 1 : prev - 1
      );
    }
    if (e.key === 'Enter') {
      if (activeIndex !== undefined) {
        const option = options.find((_, i) => i === activeIndex);
        if (option) {
          onChange(option.value);
        }
      }
    }
  };

  useEffect(() => {
    if (activeIndex !== undefined && isShown) {
      const contentWrapper = menuRef.current;

      if (contentWrapper) {
        const targetEl = contentWrapper.children[activeIndex];
        const targetRect = targetEl?.getBoundingClientRect();
        const containerRect = contentWrapper.getBoundingClientRect();

        if (
          targetEl.parentNode &&
          targetRect &&
          containerRect &&
          (targetRect.bottom > containerRect.bottom ||
            targetRect.top < containerRect.top)
        ) {
          (targetEl.parentNode as HTMLElement).scrollTop = (
            targetEl as HTMLElement
          ).offsetTop;
        }
      }
    }
  }, [isShown, activeIndex]);

  return (
    <div onKeyDown={handleKeyDown}>
      {label && <div className='mb-1 text-sm text-[#5A607F]'>{label}</div>}
      <div className='relative' ref={ref}>
        <button
          type='button'
          className={classNames(
            'border rounded py-2 px-4 text-[#5A607F] disabled:bg-gray-300 disabled:cursor-not-allowed placeholder:text-[#D9E1EC] w-full min-h-[42px] cursor-pointer flex justify-between',
            className,
            {
              'border-[#D9E1EC] focus:outline-secondary': !error,
              'border-[#F32227] focus:outline-[#F32227]': error,
            }
          )}
          onClick={() => setIsShown(!isShown)}
          disabled={disabled}
        >
          <div
            className={classNames('grid', {
              'text-[#D9E1EC]': !value,
            })}
          >
            <div className='truncate'>
              {optionsLabels[value] || placeholder}
            </div>
          </div>
          <span
            className={classNames('transition-transform w-6 h-6', {
              'rotate-90': isShown,
            })}
          >
            <ChevronRight />
          </span>
        </button>
        {isShown && (
          <div
            className='absolute w-full bg-white rounded py-2 z-10 shadow-md max-h-[300px] overflow-y-auto MiniScrollbar animate-fadeIn'
            ref={menuRef}
          >
            {options
              .filter((o, i, self) => self.indexOf(o) === i)
              .map((v) => (
                <div
                  key={v.value}
                  className={classNames(
                    'py-2 hover:bg-[#ECF2FF] text-secondary px-4 cursor-pointer',
                    {
                      'bg-[#ECF2FF]':
                        v.value === value || options.indexOf(v) === activeIndex,
                    }
                  )}
                  onClick={() => {
                    onChange(v.value);
                    close();
                  }}
                >
                  {v.label}
                </div>
              ))}
          </div>
        )}
      </div>
      {error && <div className='text-[#F32227] text-xs'>{error}</div>}
    </div>
  );
};
