import { useEffect, useState } from 'react';
import DocuSign from '../components/Steps/DocuSign';
import TaxStatus from '../components/Steps/TaxStatus';
import Survey from '../components/Steps/Survey';
import MultiStepProgressBar from '../components/MultiStepProgressBar/MultiStepProgressBar';
import { useSteps } from '../contexts/useSteps';
import { getStep } from '../core/api';
import { InviteStatusEnum, Step } from '../types';
import { Spinner } from '../components/Spinner';
import { Completed } from '../components/Steps/Completed';
import {
  isEnvelopeEvent,
  isFileinviteEvent,
  isTaxstatusEvent,
  useWSMessageHandler,
} from '../hooks/useWSMessageHandler';
import { useAuth0 } from '@auth0/auth0-react';
import { useContact } from '../hooks/useContact';
import DocIcon from '../icons/doc.png';
import Survey1Icon from '../icons/survey1.png';
import TaxIcon from '../icons/tax.png';
import CompleteIcon from '../icons/complete.png';
import FileInvite from '../components/Steps/FileInvite';
import FileUploadIcon from '../icons/file-upload.png';
// import { Survey2Step } from '../components/Steps/Survey2Step';
// import Survey2Icon from '../icons/survey2.webp';

export const Steps = () => {
  const { currentStep, setCurrentStep } = useSteps();
  const [isLoadingSteps, setIsLoadingSteps] = useState(true);
  const { user } = useAuth0();
  const { isLoadingContact, contact } = useContact();

  const STEPS: Step[] = [
    {
      step: 'Complete Survey',
      image: Survey1Icon,
      component: <Survey />,
      imageClassName: 'lg:w-8 lg:h-8 w-6 h-6',
    },
    {
      step: 'Sign the Contract',
      image: DocIcon,
      component: <DocuSign />,
      imageClassName: 'lg:w-8 lg:h-8 w-6 h-6',
    },
    {
      step: 'Complete TaxStatus verification',
      image: TaxIcon,
      component: <TaxStatus />,
      imageClassName: 'lg:w-8 lg:h-8 w-6 h-6',
    },
    {
      step: 'File Invite',
      image: FileUploadIcon,
      component: <FileInvite />,
      imageClassName: 'lg:w-10 lg:h-10 w-8 h-8',
    },
    {
      step: 'Complete',
      image: CompleteIcon,
      component: <Completed />,
      imageClassName: 'lg:w-10 lg:h-10 w-8 h-8',
    },
    // { step: 'ERC Issued, Make Payment', page: 'pagesix', image: 'wallet.png' },
  ];

  useEffect(() => {
    setIsLoadingSteps(true);
    getStep()
      .then((res) => {
        setCurrentStep(res);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoadingSteps(false);
      });
    // eslint-disable-next-line
  }, []);

  useWSMessageHandler((v) => {
    console.log('useWSMessageHandler', v);
    if (
      v &&
      isEnvelopeEvent(v) &&
      v.email === user!.email &&
      currentStep <= 1
    ) {
      setCurrentStep(2);
    }

    if (
      v &&
      isFileinviteEvent(v) &&
      v.email === user!.email &&
      currentStep <= 3 &&
      v
    ) {
      if (v.status === InviteStatusEnum.returned) {
        setCurrentStep(4);
      }
    }

    if (
      v &&
      isTaxstatusEvent(v) &&
      v.status === 'Consent completed' &&
      v.email === user!.email &&
      currentStep <= 2
    ) {
      setCurrentStep(3);
    }
  }, []);

  if (isLoadingSteps || isLoadingContact) {
    return <Spinner />;
  }

  return (
    <>
      <MultiStepProgressBar
        steps={STEPS}
        contactName={contact?.firstname_dsh}
      />
      {STEPS[currentStep].component}
    </>
  );
};
