import { useEffect, useState } from 'react';
import { postTaxPayers } from '../../core/api';
import { useToast } from '../../contexts/useToast';
import { Spinner } from '../Spinner';
import * as Sentry from '@sentry/react';

const TaxStatus = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [link, setLink] = useState('');
  const { toast } = useToast();

  useEffect(() => {
    setIsLoading(true);
    postTaxPayers()
      .then((res) => {
        setLink(res.url);
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      })
      .catch((err) => {
        Sentry.captureException(err);
        toast(err.response?.data?.message || 'Something went wrong', 'error');
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading && (
        <Spinner text='Please wait while your link is being generated' />
      )}
      {link && (
        <iframe
          className='border-none rounded-md w-full min-h-screen'
          title='TaxStatus'
          src={link}
          onLoad={() => {
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);
          }}
        ></iframe>
      )}
    </>
  );
};

export default TaxStatus;
