import { Step as IStep } from '../../types';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import { useSteps } from '../../contexts/useSteps';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import classNames from 'classnames';
import './MultiStepProgressBar.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { Alert } from '../Alert';
import { patchStep } from '../../core/api';
import { useToast } from '../../contexts/useToast';
import 'react-step-progress-bar/styles.css';
import { IoIosWarning } from 'react-icons/io';
interface Props {
  steps: IStep[];
  contactName?: string;
}

const STEPS_DESCRIPTIONS = [
  'This information gets us started preparing your Employee Retention Credit claim.',
  'This is the portal that allows you to upload any information that we require.',
  'This is the contract that governs our work on your Employee Retention Credit claim.',
  'This tool allows us to monitor your claim to keep you informed about its status.',
];

const MultiStepProgressBar = ({ steps, contactName }: Props) => {
  const { logout } = useAuth0();
  const { currentStep, setCurrentStep, payrollLink, fileInviteLink } =
    useSteps();
  const { toast } = useToast();

  const phone = process.env.REACT_APP_PHONE || '';

  return (
    <div
      className='main-progress-bar mb-8 w-full space-y-6 pt-6'
      data-testid='progress-bar'
    >
      <div className='dashboardSec'>
        <h1>Hello, {contactName}</h1>
        <Button variant='contained' color='primary' onClick={() => logout()}>
          Logout
        </Button>
      </div>

      <nav className='flex flex-col gap-y-4 lg:flex-row lg:gap-x-14'>
        <div className='relative flex min-h-[77px] flex-grow items-center rounded-md bg-white px-4 py-2'>
          <ProgressBar
            percent={(currentStep * 100) / (steps.length - 1)}
            className='w-full'
          >
            {steps.map((step, index) => (
              <Step key={index}>
                {() => (
                  <div
                    className={classNames('indexedStep', {
                      accomplished: currentStep > index,
                      current: currentStep === index,
                    })}
                  >
                    <div className='flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-[rgb(223,220,220)]'>
                      <img
                        className={step.imageClassName || ''}
                        src={step.image}
                        alt={`Step ${index + 1}`}
                      />
                    </div>
                  </div>
                )}
              </Step>
            ))}
          </ProgressBar>
        </div>
        <div className='flex flex-col space-y-1 rounded-md bg-white px-6 py-2'>
          <div className='font-semibold'>Projected ERC</div>
          <div>Pending...</div>
          {/* <img src='/images/flag.png' alt='Flag' />
              <div>189,000</div> */}
        </div>
      </nav>
      <Alert>
        <IoIosWarning size={24} className='mt-[2px] shrink-0 text-white' />
        <div className='flex flex-wrap items-center gap-x-2'>
          If you see a mistake or need to change any information, call our
          Customer Service Line
          <a
            href={`tel:${phone
              .split('')
              .filter((v) => !isNaN(+v))
              .join('')}`}
            className='flex items-center gap-2 font-semibold text-white underline hover:no-underline'
          >
            <PhoneIcon className='mt-[2px] shrink-0' />
            {phone}
          </a>
        </div>
      </Alert>
      {steps[currentStep]?.step !== 'Complete' &&
        steps[currentStep]?.step !== 'Survey' && (
          <div className='flex flex-wrap items-center justify-between gap-6'>
            <div className='flex shrink-0 items-center gap-x-4 text-base'>
              Next step:{' '}
              <div className='rounded-md bg-white px-4 py-2 font-bold text-secondary'>
                {steps[currentStep]?.step}{' '}
              </div>{' '}
            </div>
            <div>
              {currentStep > 1 &&
                currentStep < steps.length - 1 &&
                currentStep !== 2 && (
                  <Button
                    variant='contained'
                    onClick={() => {
                      patchStep(currentStep + 1)
                        .then(() => {
                          setCurrentStep((prev) => prev + 1);
                        })
                        .catch((err) => {
                          toast('Something went wrong!', 'error');
                        });
                    }}
                  >
                    {(payrollLink && currentStep === 4) ||
                    (fileInviteLink && currentStep === 3)
                      ? 'Proceed to the Next Step'
                      : 'Skip for now'}
                  </Button>
                )}
            </div>
          </div>
        )}
      <Alert>
        Please note: As of 12:00 am PDT on April 9, 2024, we are no longer
        accepting applications for tax year 2020. Any completed applications
        (with supporting documentation) submitted on or after 12:00 am PDT on
        April 9, 2024, will be prepared and filed for tax year 2021 only. By
        continuing to utilize our service, you acknowledge and agree that
        SmartERC will not prepare nor file your Forms 941X for any quarter of
        tax year 2020.
      </Alert>
      {currentStep !== 4 && (
        <div className='rounded-md bg-white px-4 py-2 text-base font-bold text-secondary'>
          {STEPS_DESCRIPTIONS[currentStep]}
        </div>
      )}
    </div>
  );
};

export default MultiStepProgressBar;
