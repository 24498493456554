import {
  forwardRef,
  FocusEventHandler,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Input } from './Input';
import { useClickAway } from 'react-use';
import classNames from 'classnames';

interface Props {
  onChange: (v: string | number) => void;
  value: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  error?: string;
  options: string[];
  name?: string;
  label?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onClose?: () => void;
  disabled?: boolean;
}

export const InputWithAutocomplete = forwardRef<HTMLInputElement, Props>(
  (
    {
      onChange,
      value,
      onBlur,
      error,
      options,
      name,
      label,
      onClose,
      onFocus,
      disabled,
    },
    ref
  ) => {
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [activeIndex, setActiveIndex] = useState<number | undefined>(
      undefined
    );
    const [isShown, setIsShown] = useState(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const close = () => {
      setIsShown(false);
      setActiveIndex(undefined);
      if (onClose) onClose();
    };
    useClickAway(wrapperRef, close);

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (!isShown || (isShown && !options.length)) return;

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setActiveIndex((prev) =>
          prev === undefined || prev === options.length - 1 ? 0 : prev + 1
        );
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setActiveIndex((prev) =>
          prev === undefined || prev === 0 ? options.length - 1 : prev - 1
        );
      }
      if (e.key === 'Enter') {
        if (activeIndex !== undefined) {
          onChange(options[activeIndex]);
          close();
        }
      }
    };

    useEffect(() => {
      if (activeIndex !== undefined && isShown) {
        const contentWrapper = menuRef.current;

        if (contentWrapper) {
          const targetEl = contentWrapper.children[activeIndex];
          const targetRect = targetEl?.getBoundingClientRect();
          const containerRect = contentWrapper.getBoundingClientRect();

          if (
            targetEl.parentNode &&
            targetRect &&
            containerRect &&
            (targetRect.bottom > containerRect.bottom ||
              targetRect.top < containerRect.top)
          ) {
            (targetEl.parentNode as HTMLElement).scrollTop = (
              targetEl as HTMLElement
            ).offsetTop;
          }
        }
      }
    }, [isShown, activeIndex]);

    return (
      <div ref={wrapperRef}>
        <div className='relative'>
          <Input
            disabled={disabled}
            onKeyDown={handleKeyDown}
            label={label}
            ref={ref}
            name={name}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onBlur={(e) => {
              if (onBlur) onBlur(e);
            }}
            value={value}
            onFocus={(e) => {
              if (onFocus) onFocus(e);
              setIsShown(true);
            }}
            error={error}
          />
          {isShown && !!options.length && value && (
            <div
              className='absolute w-full bg-white rounded py-2 z-10 shadow-md max-h-[300px] overflow-y-auto MiniScrollbar animate-fadeIn'
              ref={menuRef}
            >
              {options
                .filter((o, i, self) => self.indexOf(o) === i)
                .map((v, i) => (
                  <div
                    key={v}
                    className={classNames(
                      'py-2 hover:bg-[#ECF2FF] text-secondary px-4 cursor-pointer',
                      { 'bg-[#ECF2FF]': i === activeIndex || v === value }
                    )}
                    onClick={() => {
                      onChange(v);
                      close();
                    }}
                  >
                    {v}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    );
  }
);
